<template>
  <div id="detail-bd">
    <van-sticky v-if="type === 2" :offset-top="90">
      <div class="info-sticky">
        <div class="info">
          <van-row type="flex" justify="space-between">
            <van-col
              style="color: rgb(46, 91, 253)"
              @click="handleToUSerDetail(project.user_id)"
              >用户昵称：{{ project.user.nickname }}
              <span v-if="project.user.remark"
                >（{{ project.user.remark }}）</span
              >
            </van-col>
            <van-col></van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>{{ project.project_id }}</van-col>
            <van-col>{{ project.state | formatProjectState }}</van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>
              <div>
                {{ project.type | formatProjectType }}
                <span v-for="(o, i) in project.pass_type.split(',')" :key="i">
                  {{ o | formatProjectPassType }}
                </span>
              </div>
            </van-col>
            <van-col>
              <div>{{ project.bet_multi }}倍 ¥{{ project.bet_money }}元</div>
            </van-col>
            <van-col v-if="project.state >= 3">
              <div class="bonus">中奖：¥{{ project.bonus_money }}元</div>
            </van-col>
          </van-row>
          <van-row type="flex" justify="space-between">
            <van-col>
              下单时间：{{ project.created_at | formatDateTime }}
            </van-col>
            <van-col>
              <span v-if="project.state >= 0">出票时间：</span>
              <span v-else>撤单时间：</span>
              {{ project.handled_at | formatDateTime }}
            </van-col>
          </van-row>
          <van-row v-if="project.state >= 3 && bonus_sub_tax > 0">
            <div class="bonus">
              奖金：¥{{ project.bonus_money }}元 - ¥{{ project.tax }}元(税)
              <span v-if="project.follow_type === 1"
                >- ¥{{ handleCommission() }}元(佣金)</span
              >
            </div>
          </van-row>
        </div>
      </div>
    </van-sticky>

    <ul class="order-list mt10 pb50" v-if="type === 2">
      <li class="card" v-for="(item, key) in matches" :key="key">
        <div class="top">
          <span>{{ item.home_team }}</span>
          <span class="ml10 mr10">vs</span>
          <span>{{ item.away_team }}</span>
        </div>
        <div class="main">
          <div class="l">
            <div>
              {{ item.number.substr(5, 1) | formatWeekday }}
            </div>
            <div>
              {{ item.number.substr(6, 3) }}
            </div>
          </div>
          <div class="r">
            <table>
              <tr>
                <td>玩法</td>
                <td>选项</td>
                <td>赛果</td>
              </tr>
              <tr v-if="options[item.number].spf.length > 0">
                <td class="wid-20">
                  胜平负({{
                    item.ft_let_point_multi > 0
                      ? "+" + item.ft_let_point_multi
                      : item.ft_let_point_multi
                  }})
                </td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].spf"
                    :key="k"
                    :class="handleResult(item, 'spf', val)"
                  >
                    {{ val | formatSportsSpf }}
                    <span v-if="item.state === -3"> （1） </span>
                    <span v-else>
                      （{{
                        handleResult(item, "spf", val) === "red"
                          ? Math.abs(item.result_bd_spf.split("#")[1])
                          : 0
                      }}）
                    </span>
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">
                    {{ item.result_bd_spf.split("#")[0] | formatSportsSpf }}
                  </span>
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>

              <tr v-if="options[item.number].bf.length > 0">
                <td class="wid-20">比分</td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].bf"
                    :key="k"
                    :class="handleResult(item, 'bf', val)"
                  >
                    {{ val | formatSportsBf }}
                    <span v-if="item.state === -3"> （1） </span>
                    <span v-else>
                      （{{
                        handleResult(item, "bf", val) === "red"
                          ? Math.abs(item.result_bd_bf.split("#")[1])
                          : 0
                      }}）
                    </span>
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">
                    {{ item.result_bd_bf.split("#")[0] | formatSportsBf }}
                  </span>
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>
              <tr v-if="options[item.number].zjq.length > 0">
                <td class="wid-20">总进球</td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].zjq"
                    :key="k"
                    :class="handleResult(item, 'zjq', val)"
                  >
                    {{ val | formatSportsZjq }}
                    <span v-if="item.state === -3"> （1） </span>
                    <span v-else>
                      （{{
                        handleResult(item, "zjq", val) === "red"
                          ? Math.abs(item.result_bd_zjq.split("#")[1])
                          : 0
                      }}）
                    </span>
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">
                    {{ item.result_bd_zjq.split("#")[0] | formatSportsZjq }}
                  </span>
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>
              <tr v-if="options[item.number].bqcspf.length > 0">
                <td>半全场</td>
                <td>
                  <span
                    v-for="(val, k) in options[item.number].bqcspf"
                    :key="k"
                    :class="handleResult(item, 'bqcspf', val)"
                  >
                    {{ val | formatSportsBqc }}
                    <span v-if="item.state === -3"> （1） </span>
                    <span v-else>
                      （{{
                        handleResult(item, "bqcspf", val) === "red"
                          ? Math.abs(item.result_bd_bqcspf.split("#")[1])
                          : 0
                      }}）
                    </span>
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">
                    {{
                      String(item.result_bd_bqcspf.split("#")[0])
                        | formatSportsBqc
                    }}
                  </span>
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>

              <tr v-if="options[item.number].sxds.length > 0">
                <td class="wid-20">上下单双</td>
                <td>
                  <span
                    class="td_div"
                    v-for="(val, k) in options[item.number].sxds"
                    :key="k"
                    :class="handleResult(item, 'sxds', val)"
                  >
                    <span class="text1">{{ val | formatBdSxds }}</span>
                    <span class="text2">
                      （{{
                        handleResult(item, "sxds", val) === "red"
                          ? Math.abs(item.result_bd_sxds.split("#")[1])
                          : 0
                      }}）
                    </span>
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">
                    {{
                      Number(item.result_bd_sxds.split("#")[0]) | formatBdSxds
                    }}
                  </span>
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>

              <tr v-if="options[item.number].sfgg.length > 0">
                <td class="wid-20">
                  胜负过关({{
                    item.bt_let_point_multi > 0
                      ? "+" + item.bt_let_point_multi
                      : item.bt_let_point_multi
                  }})
                </td>
                <td>
                  <span
                    class="td_div"
                    v-for="(val, k) in options[item.number].sfgg"
                    :key="k"
                    :class="handleResult(item, 'sfgg', val)"
                  >
                    <span class="text1">{{ val | formatBdSfgg }}</span>
                    <span class="text2">
                      （{{
                        handleResult(item, "sfgg", val) === "red"
                          ? Math.abs(item.result_bd_sfgg.split("#")[1])
                          : 0
                      }}）
                    </span>
                  </span>
                </td>
                <td class="wid-20">
                  <span v-if="item.state === 13">
                    {{
                      Number(item.result_bd_sfgg.split("#")[0]) | formatBdSfgg
                    }}
                  </span>
                  <span v-if="item.state === -3"> 赛事无效 </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </li>
    </ul>

    <div v-if="childOptions && type === 1" class="child">
      <div class="title">
        <p class="red tac" style="background: #fff">
          总方案金额：{{ project.bet_money }}元
        </p>
        <van-row
          type="flex"
          style="height: 44px; background: #fff"
          justify="space-between"
          align="center"
        >
          <van-col span="12">
            <span class="ml16"
              >实票(共 {{ tickets.length }}页{{ handleCalcCount() }} 张)</span
            >
          </van-col>
          <van-col span="12" class="tar">
            <span class="mr5"
              >订单尾号：{{ project.project_id.slice(-6) }}
            </span>
          </van-col>
        </van-row>
      </div>

      <div class="body pb50">
        <van-swipe
          ref="swipe"
          @change="handleChange"
          :touchable="false"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, key) in tickets" :key="key">
            <div class="child-content">
              <div class="child-tag">
                注意：该投注单需要扫描<span>{{ item.count }}</span
                >次 <span>{{ item.bet_multi }} </span>倍
              </div>
              <div class="child-type ft14 mb10">
                玩法：{{ project.play_type | formatProjectPayType }}<br />
              </div>

              <ul class="order-list">
                <li
                  class="card"
                  v-for="(item1, key1) in Object.keys(childOptions[key]).sort()"
                  :key="key1"
                  :class="String(nowDate) !== item1.substr(5, 1) ? 'red' : ''"
                >
                  <div v-if="item1 !== 'bet_multi' && item1 !== 'count'">
                    <span class="order-week">
                      {{ item1.substr(5, 1) | formatWeekday }}
                      {{ item1.substr(6, 3) }}
                    </span>
                    <span class="order-result">
                      <span
                        v-for="(val, k) in childOptions[key][item1].spf"
                        :key="k"
                      >
                        {{ val | formatSportsSpf }}（{{
                          resultRates[item1]["spf"][val]
                        }}）
                        <label>,</label>
                      </span>
                      <span
                        v-for="(val, k) in childOptions[key][item1].bf"
                        :key="k"
                      >
                        {{ val | formatSportsBf }}（{{
                          resultRates[item1]["bf"][val]
                        }}）
                        <label>,</label>
                      </span>
                      <span
                        v-for="(val, k) in childOptions[key][item1].zjq"
                        :key="k"
                      >
                        {{ val | formatSportsZjq }}（{{
                          resultRates[item1]["zjq"][val]
                        }}）
                        <label>,</label>
                      </span>
                      <span
                        v-for="(val, k) in childOptions[key][item1].bqcspf"
                        :key="k"
                      >
                        {{ val | formatSportsBqc }}（{{
                          resultRates[item1]["bqcspf"][val]
                        }}）
                        <label>,</label>
                      </span>

                      <span
                        v-for="(val, k) in childOptions[key][item1].sxds"
                        :key="k"
                      >
                        {{ val | formatBdSxds }}（{{
                          resultRates[item1]["sxds"][val]
                        }}）
                        <label>,</label>
                      </span>

                      <span
                        v-for="(val, k) in childOptions[key][item1].sfgg"
                        :key="k"
                      >
                        {{ val | formatBdSfgg }}（{{
                          resultRates[item1]["sfgg"][val]
                        }}）
                        <label>,</label>
                      </span>
                    </span>
                  </div>
                </li>
              </ul>

              <div class="ml40 mr40 ft12 gray0">
                <van-row class="mt10">
                  <van-col span="24">
                    串关：<span
                      v-for="(o, i) in project.pass_type.split(',')"
                      :key="i"
                    >
                      {{ o | formatProjectPassType }}
                    </span>
                    倍数：<span>{{ item.bet_multi }}</span>
                  </van-col>
                </van-row>
                <van-row class="mt20">
                  <van-col span="12"
                    >金额：<span>{{ item.bet_money }}元</span></van-col
                  >
                  <van-col span="12">
                    最大奖金：¥<span>{{ max_prize.toFixed(2) }}</span>
                  </van-col>
                </van-row>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>

        <div class="nav">
          <van-icon
            class="arrow-left"
            v-show="childOptions.length > 1"
            name="arrow-left"
            @click="handleLast"
            style="font-weight: bold"
            color="#1989fa"
            size="34"
          />

          <van-icon
            class="arrow-right"
            v-show="childOptions.length > 1"
            name="arrow"
            @click="handleNext"
            style="font-weight: bold"
            color="#1989fa"
            size="34"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Decimal } from "decimal.js";
export default {
  name: "detail-bd",
  props: [
    "project",
    "matches",
    "options",
    "rates",
    "resultRates",
    "childOptions",
    "tickets",
    "type",
    "bonus_sub_tax",
  ],
  data() {
    return {
      match: {},
      childIndex: 0,
      ticketCount: 0,
      nowDate: new Date().getDay(),
      max_prize: 0,
    };
  },
  created() {
    this.matches.forEach((o) => {
      this.match[o.number] = {
        away_team: o.away_team,
        home_team: o.home_team,
        league: o.league,
        ft_let_point_multi: o.ft_let_point_multi,
      };
    });
    this.handleJudgeLast();
    this.$emit("handleIsLast", true);
    this.handleCalcMaxPrize();
  },
  methods: {
    handleToUSerDetail(val) {
      this.$router.push({
        name: "account-book-customer-detail",
        query: {
          user_id: val,
        },
      });
    },
    // 计算佣金金额
    handleCommission() {
      // 中奖金额 - 投注金额  剩余的7%
      if (Number(this.project.bonus_money) > Number(this.project.bet_money)) {
        let temp = new Decimal(this.project.bonus_money)
          .sub(this.project.bet_money)
          .mul(0.07);
        return temp;
      } else {
        return 0;
      }
    },
    handleCalcCount() {
      return this.tickets
        .map((o) => {
          return o.count;
        })
        .reduce((x, y) => x + y, 0);
    },
    handlePrintFnc() {
      this.$emit("handlePrint", {
        o: this.tickets[this.childIndex],
        i: this.childIndex,
      });
    },
    handleChange(val) {
      this.childIndex = val;
      this.handleCalcMaxPrize(val);
      this.handleJudgeLast();
      this.handleScrollTo();
    },
    handleJudgeLast() {
      if (this.childIndex + 1 === this.tickets.length) {
        this.$emit("handleIsLast", true);
      } else {
        this.$emit("handleIsLast", false);
      }
    },
    handleScrollTo() {
      let order_list_height =
        document.getElementsByClassName("order-list")[0].clientHeight + 10;
      document.getElementsByClassName("item-detail")[0].scrollTop =
        order_list_height;
    },
    handleLast() {
      if (this.childIndex === 0) {
        this.$toast("当前第一张票");
        return;
      }
      this.$refs.swipe.prev();
    },
    handleNext() {
      if (this.childIndex + 1 === this.tickets.length) {
        this.$toast("当前最后一张票");
        return;
      }
      this.$refs.swipe.next();
    },
    handlePrint() {
      let obj = this.tickets[this.childIndex];
      obj.childIndex = this.childIndex;
      obj.sum = this.tickets.length;

      this.$emit("handlePrint", obj);
    },
    handleResult(item, type, value) {
      if (
        Number(item["result_bd_" + type].split("#")[0]) === Number(value) &&
        (item.state === 13 || item.state === -3)
      ) {
        return "red";
      }
      return "";
    },
    handleCalcMaxPrize(i = 0) {
      // 拆单每个场次只有有一种玩法 只有取最大值就可以
      // 最大赔率数组
      let self = this;
      let max_prize_array = [];
      this.max_prize = 0;

      Object.keys(this.childOptions[i]).forEach((o) => {
        let t = [];
        let obj = this.childOptions[i][o];

        Object.keys(obj).forEach((o1) => {
          if (obj[o1].length > 0) {
            t = obj[o1].map((o2, i2) => {
              return self.rates[o][o1][i2];
            });
          }
        });
        max_prize_array.push(Math.max(...t));
      });

      // 串关数
      let chuan = this.project.pass_type.split(",").map((o) => {
        return o.split("_")[0];
      });

      chuan.forEach((o) => {
        let temp = this.bonusPermutation(max_prize_array, o);

        let temp_array = temp.map((o1) => {
          return o1.reduce((x, y) => new Decimal(x).mul(y), 1);
        });

        let t = new Decimal(
          temp_array.reduce((x, y) => new Decimal(x).add(y), 0)
        )
          .mul(2)
          .mul(this.tickets[i].bet_multi);

        this.max_prize = new Decimal(this.max_prize).add(t);
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
#detail-bd {
  .info-sticky{
    background:#f0f0f0;
    padding-top: 10px;
    .info {
      padding: 10px;
      background: #ffffff;
      font-size: 14px;
      color: #888888;

      .bonus {
        color: #f00;
      }

      div {
        padding: 2px;
      }
    }
  }

  .order-list {
    .card {
      padding: 10px;
      background: #ffffff;
      margin-bottom: 10px;
      border-radius: 5px;

      .top {
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
      }

      .main {
        display: flex;
        flex-direction: row;

        .l {
          padding-right: 5px;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #888888;

          div {
            padding: 2px 0;
          }
        }

        .r {
          flex: 1;
        }
      }
    }

    .card.red{
      span{
        color:red!important;
      }
    }

    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      font-size: 12px;

      tr {
        height: 20px;
        border: 1px solid #ddd;
      }

      td {
        border: 1px solid #ddd;
        padding: 0 4px;
      }

      .red {
        color: red;
      }
    }
  }

  .child {
    .body {
      position: relative;
      background: #fff;
    }
    .child-content {
      position: relative;
      background: #fff;

      .child-tag {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        text-align: center;
        color: red;

        span {
          font-weight: bold;
          font-size: 20px;
        }
      }

      .child-type{
        padding: 45px 40px 0;
      }


      ul {
        background: #fff;
        padding: 0px 30px 0;

        li {
          margin-bottom: 0px !important;
          >div {
            font-size: 12px;
            display: flex;
            align-items: center;
            .order-week {
              width: 50px;
              margin-right: 10px;
              color: #888;
            }
            .order-result {
              flex: 1;
              span {
                &:last-child {
                  label {
                    &:last-child {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .nav {
      display: flex;
      align-items: center;
      height: 50px;
      justify-content: space-evenly;
      background: #fff;
      .arrow-left {
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -12px;
      }
      .arrow-right {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -12px;
      }
    }
  }
}
</style>
