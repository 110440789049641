<template>
  <div id="project-sports-detail">
    <c-nav-bar title="方案详情"></c-nav-bar>
    <div class="content">
      <div class="item">
        <van-tabs
          v-model="tabIndex"
          title-active-color="#38A6F3"
          color="#38A6F3"
        >
          <van-tab title="拆单详情">
            <div class="item-detail" v-if="project.project_id">
              <detail-bd
                v-if="project.type === 'bd'"
                :project="project"
                :options="options"
                :childOptions="childOptions"
                :tickets="tickets"
                :matches="matches"
                :rates="rates"
                :resultRates="resultRates"
                :type="1"
                :bonus_sub_tax="bonus_sub_tax"
              ></detail-bd>
            </div>
          </van-tab>
          <van-tab title="方案详情">
            <div class="item-detail" v-if="project.project_id">
              <detail-bd
                v-if="project.type === 'bd'"
                :project="project"
                :options="options"
                :childOptions="childOptions"
                :tickets="tickets"
                :matches="matches"
                :rates="rates"
                :resultRates="resultRates"
                :bonus_sub_tax="bonus_sub_tax"
                :type="2"
              ></detail-bd>
            </div>
          </van-tab>
          <van-tab title="实票照片">
            <div v-if="project.ticket_img_urls">
              <div
                v-if="
                  project.ticket_img_urls.length > 0 && againUpload === false
                "
              >
                <van-swipe class="ticket-image" :autoplay="3000">
                  <van-swipe-item
                    class="tac"
                    v-for="(o, i) in project.ticket_img_urls"
                    :key="i"
                  >
                    <img style="max-height: 60vh; max-width: 100%" :src="o" />
                  </van-swipe-item>
                </van-swipe>
              </div>
              <div
                v-if="project.ticket_img_urls.length === 0 || againUpload"
                class="detail-upload mt10"
              ></div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <!-- <p-tabbar
      ref="tabbar"
      :type="type"
      @handleAllPrint="handleAllPrint"
      :state="project.state"
      :ticketImage="project.ticket_img_urls"
    ></p-tabbar> -->
  </div>
</template>

<script>
import CNavBar from "@/components/CNavBar";
// import PTabbar from "@/components/PTabbar";
// import DRecharge from "@/components/DRecharge";
import DetailBd from "@/views/project/detail/Bd";

// import common from "@/api/common";
import project from "@/api/project";

import { Decimal } from "decimal.js";

import { mapState } from "vuex";

export default {
  name: "project-bd-detail",
  data() {
    return {
      tabIndex: 0,
      project: {},
      matches: [],
      options: {},
      rates: {},
      resultRates: {},
      tickets: [],
      childOptions: [],
      type: "jczq",
      pk: null,
      ticketsImage: [],
      bonus_sub_tax: 0,
      againUpload: false,
    };
  },
  components: {
    CNavBar,
    // PTabbar,
    DetailBd,
    // DRecharge,
  },
  created() {
    this.type = this.$route.query.type;
    this.pk = this.$route.query.pk;
    this.handleInit();
    this.tabIndex = this.$route.query.from === "ticket" ? 2 : 0;
  },
  computed: {
    ...mapState({
      area: (state) => state.store.information.area,
    }),
  },
  methods: {
    // handleIsLast(obj) {
    //   this.$refs.tabbar.isLast = obj;
    // },
    handleInit() {
      let pk = this.$route.query.pk;
      project
        .getBd(pk)
        .then((res) => {
          this.project = res.results.project;
          this.matches = res.results.matches.sort((a, b) => {
            return Number(a.number.substr(6)) - Number(b.number.substr(6));
          });
          this.handlePackOptionData(this.project);
          let y = this.handlePackChildOptionData(this.project);

          this.tickets = [];
          let temp = parseInt(this.project.bet_multi / 99);
          let remain = this.project.bet_multi % 99;
          if (this.project.bet_multi > 99) {
            if (remain === 1) {
              if (temp > 1) {
                this.tickets.push({
                  count: temp - 1,
                  bet_multi: 99,
                  bet_money: new Decimal(this.project.bet_money)
                    .div(this.project.bet_multi)
                    .mul(99),
                });
                this.childOptions.push(y);
              }
              this.tickets.push({
                count: 1,
                bet_multi: 98,
                bet_money: new Decimal(this.project.bet_money)
                  .div(this.project.bet_multi)
                  .mul(98),
              });

              this.tickets.push({
                count: 1,
                bet_multi: 2,
                bet_money: new Decimal(this.project.bet_money)
                  .div(this.project.bet_multi)
                  .mul(2),
              });
            } else {
              this.tickets.push({
                count: temp,
                bet_multi: 99,
                bet_money: new Decimal(this.project.bet_money)
                  .div(this.project.bet_multi)
                  .mul(99),
              });

              this.tickets.push({
                count: 1,
                bet_multi: remain,
                bet_money: new Decimal(this.project.bet_money)
                  .div(this.project.bet_multi)
                  .mul(remain),
              });
            }
            this.childOptions.push(y);
            this.childOptions.push(y);
          } else {
            this.tickets.push({
              count: 1,
              bet_multi: this.project.bet_multi,
              bet_money: new Decimal(this.project.bet_money),
            });
            this.childOptions.push(y);
          }

          // if (this.project.bet_multi >= 99) {
          //   y.bet_multi = 99;
          //   y.count = parseInt(this.project.bet_multi / 99);
          //   this.childOptions.push(y);
          // }
          // if (this.project.bet_multi % 99 !== 0) {
          //   let y1 = Object.assign({}, y);
          //   y1.bet_multi = this.project.bet_multi % 99;
          //   y1.count = 1;
          //   this.childOptions.push(y1);
          // }
          this.bonus_sub_tax = new Decimal(this.project.bonus_money).sub(
            new Decimal(this.project.tax)
          );

          this.ticketsImage = res.results.project.ticket_img_urls.map((o) => {
            return {
              url: o,
              status: "success",
            };
          });
        })
        .catch((res) => {
          this.$refs.recharge.handleShow(res.code, res.message);
        });
    },
    handlePackOptionData(data) {
      let arrBetMatch = data.bet_match.split(",");
      let arrBetCodes = data.bet_codes.split(",");
      let arrBetRates = data.bet_rates.split(",");

      console.log(data);
      let objOptions = {};
      let objRates = {};

      arrBetMatch.forEach((item, i) => {
        let strBetCodes = arrBetCodes[i];
        let strBetRates = arrBetRates[i];
        objOptions[item] = {
          spf: [],
          zjq: [],
          bf: [],
          bqcspf: [],
          sfgg: [],
          sxds: [],
        };
        objRates[item] = {
          spf: [],
          zjq: [],
          bf: [],
          bqcspf: [],
          sfgg: [],
          sxds: [],
        };

        objOptions[item][data.play_type] = strBetCodes.split("/");
        objRates[item][data.play_type] = strBetRates.split("/");
        console.log("objRates", objRates);
      });

      this.options = objOptions;
      this.rates = objRates;

      let i3 = {};
      Object.keys(objRates).forEach((o) => {
        this.$set(i3, o, {});

        Object.keys(objRates[o]).forEach((o1) => {
          this.$set(i3[o], o1, {});

          objRates[o][o1].forEach((o2, i2) => {
            this.$set(i3[o][o1], objOptions[o][o1][i2], o2);
          });
        });
      });

      this.$set(this, "resultRates", JSON.parse(JSON.stringify(i3)));
    },
    handlePackChildOptionData(data) {
      let arrBetMatch = data.bet_match.split(",");
      let arrBetCodes = data.bet_codes.split(",");
      let objOptions = {};
      arrBetMatch.forEach((item, i) => {
        let strBetCodes = arrBetCodes[i];

        let temp = strBetCodes.split("/");
        if (data.play_type === "spf") {
          temp = temp.reverse();
        }
        objOptions[item] = {};
        objOptions[item][data.play_type] = temp;
      });
      console.log("objOptions", objOptions);
      return objOptions;
    },
    handleAllPrint() {
      this.tickets.forEach((o) => {
        console.log("o", o);
        // this.handlePrint(o, i);
      });
    },

    handlePrintDetail(m, b, p) {
      // 投注顺序为 让球胜平负 总进球 比分 半全场 胜平负
      // 打印机顺序 胜平负 a , 让球胜平负 b, 比分 c, 总进球 d, 半全场 e
      let temp = b.map((o) => {
        let arr = [];
        let f = {
          f_rqspf_f: 0,
          f_zjq_f: 1,
          f_bf_f: 2,
          f_bqspf_f: 3,
          f_spf_f: 4,
        };

        if (p === "f_hhgg_f") {
          arr = o.trim().split("|");
        } else {
          arr[f[p]] = o.trim();
        }

        let o2 = arr.map((o1, i1) => {
          let g = [];
          let l = ["b", "d", "c", "e", "a"];
          if (o1) {
            g = o1.split("/").map((o3) => {
              return l[i1] + o3;
            });
          }
          return g.join("/");
        });

        // 顺序调整
        let result = [o2[4], o2[0], o2[2], o2[1], o2[3]].filter(function (s) {
          return s && s.trim();
        });

        return result.join("/");
      });

      return temp
        .map((o, i) => {
          return m[i].substr(8, 1) + "|" + m[i].substr(9, 3) + "|" + o;
        })
        .join(",");
    },
    handlePrintBetType(c, b) {
      let bet = "";
      let type = b;
      switch (c) {
        case 3:
          if (type === "2_1") {
            bet = "3_3";
          } else if (type === "2_1,3_1") {
            bet = "3_4";
          } else if (type === "3_1") {
            bet = "3_1";
          }
          break;
        case 4:
          if (type === "2_1") {
            bet = "4_6";
          } else if (type === "3_1") {
            bet = "4_4";
          } else if (type === "3_1,4_1") {
            bet = "4_5";
          } else if (type === "4_1") {
            bet = "4_1";
          }
          break;
        case 5:
          if (type === "4_1") {
            bet = "5_5";
          } else if (type === "4_1,5_1") {
            bet = "5_6";
          } else if (type === "2_1") {
            bet = "5_10";
          } else if (type === "3_1,4_1,5_1") {
            bet = "5_16";
          } else if (type === "2_1,3_1") {
            bet = "5_20";
          } else if (type === "2_1,3_1,4_1,5_1") {
            bet = "5_26";
          } else if (type === "5_1") {
            bet = "5_1";
          }
          break;
        case 6:
          if (type === "5_1") {
            bet = "6_6";
          } else if (type === "5_1,6_1") {
            bet = "6_7";
          } else if (type === "2_1") {
            bet = "6_15";
          } else if (type === "3_1") {
            bet = "6_20";
          } else if (type === "4_1,5_1,6_1") {
            bet = "6_22";
          } else if (type === "2_1,3_1") {
            bet = "6_35";
          } else if (type === "3_1,4_1,5_1,6_1") {
            bet = "6_42";
          } else if (type === "2_1,3_1,4_1") {
            bet = "6_50";
          } else if (type === "2_1,3_1,4_1,5_1,6_1") {
            bet = "6_57";
          } else if (type === "6_1") {
            bet = "6_1";
          }
          break;
        case 7:
          if (type === "6_1") {
            bet = "7_7";
          } else if (type === "6_1,7_1") {
            bet = "7_8";
          } else if (type === "5_1") {
            bet = "7_21";
          } else if (type === "4_1") {
            bet = "7_35";
          } else if (type === "2_1,3_1,4_1,5_1,6_1,7_1") {
            bet = "7_120";
          } else if (type === "7_1") {
            bet = "7_1";
          }
          break;
        case 8:
          if (type === "7_1") {
            bet = "8_8";
          } else if (type === "7_1,8_1") {
            bet = "8_9";
          } else if (type === "6_1") {
            bet = "8_28";
          } else if (type === "5_1") {
            bet = "8_56";
          } else if (type === "4_1") {
            bet = "8_70";
          } else if (type === "2_1,3_1,4_1,5_1,6_1,7_1,8_1") {
            bet = "8_247";
          } else if (type === "8_1") {
            bet = "8_1";
          }
          break;
      }

      return bet;
    },
    handlePrintCode(p) {
      let temp = "";
      switch (p) {
        case "f_hhgg_f":
          temp = 56;
          break;
        case "f_spf_f":
          temp = 51;
          break;
        case "f_bf_f":
          temp = 52;
          break;
        case "f_zjq_f":
          temp = 53;
          break;
        case "f_bqspf_f":
          temp = 54;
          break;
        case "f_rqspf_f":
          temp = 55; // to do
          break;
      }
      return temp;
    },
  },
};
</script>

<style lang="stylus" scoped>
#project-sports-detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    height: clac(100% - 46px);
    flex: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 46px;
    bottom: 44px;

    .info {
      padding: 10px;
      background: #ffffff;
      font-size: 14px;
      color: #888888;

      .bonus {
        color: #f00;
      }

      div {
        padding: 2px;
      }
    }

    .item-detail {
      overflow: auto;
    }
  }

  .ticket-image {
    width: 90%;
    margin: 20px auto;
  }

  .gray {
    color: #666;
    padding: 0 5px;
  }

  .detail-upload {
    background #fff
    padding: 20px;
    margin-bottom: 10px;
  }
  .detail-upload-btn {
    width: 172px;
    margin: 0 auto;
  }
}
</style>
